// let items = document.querySelectorAll('.examples .carousel .carousel-item');

// items.forEach((el) => {
//     const minPerSlide = 3;
//     let next = el.nextElementSibling;
//     for (var i=1; i<minPerSlide; i++) {
//         if (!next) {
//             // wrap carousel by using first child
//             next = items[0];
//         }
//         let cloneChild = next.cloneNode(true);
//         el.appendChild(cloneChild.children[0]);
//         next = next.nextElementSibling;
//     }
// });

document.addEventListener("DOMContentLoaded", function () {
    const video = document.getElementById("header-video");
    const loadingOverlay = document.getElementById("loading-overlay");

    // Event-Listener, um den Fortschritt des Videoladens zu überwachen
    video.addEventListener("loadedmetadata", () => {
        video.play().then(() => {
            // Video spielt im Autoplay-Modus
            // Verstecke das Overlay
            loadingOverlay.style.display = "none";
        });
    });
    // video.addEventListener("progress", () => {
    //     const loadedPercentage = (video.buffered.end(0) / video.duration) * 100;

    //     if (loadedPercentage >= 100) {
    //         // Video ist vollständig geladen und kann abgespielt werden
    //         video.play().then(() => {
    //             // Video spielt im Autoplay-Modus
    //             // Verstecke das Overlay
    //             loadingOverlay.style.display = "none";
    //         });
    //     }
    // });
});


// Diese Funktion kopiert den Inhalt der nächsten 2 Carousel-Elemente für jedes Carousel separat
function copyCarouselItems(carousel) {
    const items = Array.from(carousel.querySelectorAll('.carousel-item'));
    const minPerSlide = 3;

    items.forEach((el, index) => {
        let next = el.nextElementSibling;

        if (!next && index === items.length - 1) {
            // Wenn es das letzte Carousel ist, starten Sie den Übergang von vorne
            next = items[0];
        }

        for (let i = 1; i < minPerSlide; i++) {
            if (!next) {
                break;
            }

            // Kopiere nur den Inhalt des nächsten Elements
            let cloneContent = next.innerHTML;
            el.insertAdjacentHTML('beforeend', cloneContent);

            if (i === 1 && !next.nextElementSibling && index === items.length - 2) {
                // Wenn es das vorletzte Carousel ist und das letzte Element, starten Sie den Übergang von vorne
                cloneContent = items[0].innerHTML;
                el.insertAdjacentHTML('beforeend', cloneContent);
            }

            next = next.nextElementSibling;

            if (!next && index === items.length - 1) {
                // Wenn es das letzte Carousel ist, starten Sie den Übergang von vorne
                next = items[0];
            }
        }
    });
}

// Rufen Sie die Funktion für jedes Carousel auf der Seite auf
const carousels = document.querySelectorAll('.examples .carousel');

carousels.forEach((carousel) => {
    copyCarouselItems(carousel);
});


// Lacy Load Videos - https://medium.com/@bogdanfromkyiv/lazy-load-for-videos-with-plain-js-ef0671dda64
document.addEventListener("DOMContentLoaded", function() {
    let lazyVideos = [...document.querySelectorAll("video.lazy")]
   
    if ("IntersectionObserver" in window) {
      let lazyVideoObserver = new IntersectionObserver(function(entries) {
        entries.forEach(function(video) {
          if (video.isIntersecting) {
            for (let source in video.target.children) {
              let videoSource = video.target.children[source];
              if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                videoSource.src = videoSource.dataset.src;
              }
            }
   
            video.target.load();
            video.target.classList.remove("lazy");
            lazyVideoObserver.unobserve(video.target);
          }
        });
      });
   
      lazyVideos.forEach(function(lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
      });
    }
});
   

// document.addEventListener('DOMContentLoaded', function () {
//     // Holen Sie sich die Carousel-Elemente und die Indikator-Elemente
//     const carouselInner = document.querySelector('.carousel-inner');
//     const carouselIndicators = document.querySelector('.carousel-indicators');

//     // Daten für Ihre Carousel-Items
//     const carouselData = [
//         {
//             imgSrc: 'https://via.placeholder.com/2000x1200.png/CB997E/333333?text=1',
//             overlayText: 'Slide 1',
//         },
//         // Fügen Sie hier Ihre anderen Carousel-Daten hinzu
//     ];

//     // Fügen Sie Carousel-Items und Indikatoren hinzu
//     carouselData.forEach((item, index) => {
//         const carouselItem = document.createElement('div');
//         carouselItem.classList.add('carousel-item');

//         if (index === 0) {
//             carouselItem.classList.add('active');
//         }

//         if (index === 1) {
//             carouselItem.classList.add('next');
//         }

//         if (index === carouselData.length - 1) {
//             carouselItem.classList.add('prev');
//         }

//         const card = document.createElement('div');
//         card.classList.add('col-12', 'card');

//         const cardImg = document.createElement('div');
//         cardImg.classList.add('card-img');

//         const img = document.createElement('img');
//         img.src = item.imgSrc;
//         img.classList.add('img-fluid');

//         const cardOverlay = document.createElement('div');
//         cardOverlay.classList.add('card-img-overlay');
//         cardOverlay.textContent = item.overlayText;

//         cardImg.appendChild(img);
//         card.appendChild(cardImg);
//         card.appendChild(cardOverlay);
//         carouselItem.appendChild(card);

//         carouselInner.appendChild(carouselItem);

//         const indicatorButton = document.createElement('button');
//         indicatorButton.type = 'button';
//         indicatorButton.setAttribute('data-bs-target', '#examples3');
//         indicatorButton.setAttribute('data-bs-slide-to', index.toString());

//         if (index === 0) {
//             indicatorButton.classList.add('active');
//             indicatorButton.setAttribute('aria-current', 'true');
//         }

//         indicatorButton.setAttribute('aria-label', `Slide ${index + 1}`);
//         carouselIndicators.appendChild(indicatorButton);
//     });

//     // Initialisieren Sie das Carousel
//     const myCarousel = new bootstrap.Carousel(document.getElementById('examples3'), {
//         interval: 5000, // Setzen Sie hier die gewünschte Intervallzeit ein
//         wrap: true,
//     });
// });

document.addEventListener("DOMContentLoaded", function () {
    // Finde die Nav-Links mit der Klasse "multi-tab-control"
    const navLinks = document.querySelectorAll(".multi-tab-control");

    // Füge einen Event Listener für den Klick auf ein Nav-Link hinzu
    navLinks.forEach(function (link) {
        link.addEventListener("click", function (event) {
            // Verhindere das Standardverhalten des Links
            event.preventDefault();

            // Hol dir das Ziel der Navigation
            // const targetTab = link.getAttribute("href");
            const targetTab = link.getAttribute("data-target");

            // Aktiviere den entsprechenden Tab in beiden Tab-Content-Bereichen
            document.querySelector("#video"+targetTab).classList.add("show", "active");
            document.querySelector("#content"+targetTab).classList.add("show", "active");

            // Deaktiviere alle anderen Tabs
            const otherTabs = document.querySelectorAll(".tab-pane:not(.video" + targetTab + ")");
            otherTabs.forEach(function (tab) {
                tab.classList.remove("show", "active");
            });
        });
    });
});

// alte zoomImage funktion
// const imageContainer = document.querySelector('.image-container');
// const zoomImage = document.querySelector('.zoom-image');

// imageContainer.addEventListener('mousemove', (e) => {
//     const xOffset = (e.clientX / imageContainer.offsetWidth - 0.5) * -400;
//     const yOffset = (e.clientY / imageContainer.offsetHeight - 0.5) * -50;

//     zoomImage.style.transition = 'transform 0.6s ease'; // Sanfte Bewegung
//     zoomImage.style.transform = `scale(1.25) translate(${xOffset}px, ${yOffset}px)`;
// });

// imageContainer.addEventListener('mouseleave', () => {
//     zoomImage.style.transition = 'transform 0.6s ease'; // Sanfte Rückkehr zur Normalgröße
//     zoomImage.style.transform = 'scale(1)';
// });




const panoramaImage = document.querySelector(".panorama-image img");

panoramaImage.addEventListener("mousemove", (e) => {
    const container = document.querySelector(".panorama-container");
    const containerWidth = container.clientWidth;
    const imageWidth = panoramaImage.width;
    const offsetX = e.clientX - container.getBoundingClientRect().left;

    const maxOffset = imageWidth - containerWidth;
    const percentage = (offsetX / containerWidth) * 100;
    const transformValue = -maxOffset * (percentage / 100);

    panoramaImage.style.transform = `translateX(${transformValue}px)`;
});
